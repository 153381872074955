export const getInitials = (user) => {
  const { profile: {
    first_name, middle_name, last_name
   }} = user

  let nameStr = (first_name || '').charAt(0)
  if (last_name) {
    nameStr += last_name.charAt(0)
  } else {
    nameStr += (middle_name || '').charAt(0)
  }
  if (nameStr) {
    return nameStr.slice(0, 2).toLocaleUpperCase()
  }
}
