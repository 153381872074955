import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button, ButtonTitle, TouchpointContainer } from "../Touchpoint"

const Background = styled.div`
height: 372px;
width: 100%;
position: absolute;
flex-shrink: 0;
background: linear-gradient(133.2deg, #E64845 7.07%, #F16D30 99.35%);
`
const Content = styled.div`
display:flex;
flex-direction: column;
width: 100%;
height: 100%;
padding: 0 25px 64.45px 25px;
align-items: center;
z-index: 100;
`

const Title = styled.span`
  font-size: 25px;
  font-weight: 600;
  color: white;
  text-align: center;
  line-height: 1.2;
  margin-top: auto;
`


export const OpenStoreListItem = () => {
  const { t } = useTranslation('translations');

  const onClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }

  return (
    <TouchpointContainer onClick={onClick}>
      <Background />
      <Content>
        <Title>{t('See more requests on Intch')}</Title>
        <Button>
          <ButtonTitle style={{ color: 'white' }}>{t('Get the App')}</ButtonTitle>
        </Button>
      </Content>
    </TouchpointContainer>
  )
}