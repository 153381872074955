import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import devices from '../devices';
import { Touchpoint } from '../components/Touchpoint/Touchpoint';
import { UserContext } from '../userContext';
import { getTouchpoints, IServTouchpoint } from '../utils/touchpoints';
import Carousel, { ReactElasticCarouselProps } from 'react-elastic-carousel'
import RightIcon from '../../public/right.svg'

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 0px 32px;
  margin-top: 120px;
  @media ${devices.laptop} {
    margin-top: 80px;
    }
`


const Title = styled.h2`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 140%;
    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
        text-align: center;
    }
`

const TocuhpointSpace = styled.div`
  width: 16px;
  flex-shrink: 0;
`
const TocuhpointWrapper = styled.div`
  padding-bottom: 10px;
`

const Container = styled.div`
      display: flex;
      margin-top: 80px;
      @media ${devices.laptop} {
        margin-top: 40px;
    }
`


const TouchpointsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: center;
  padding: 0 100px 0 100px;
  @media screen and (max-width: 600px) {
      padding: 0 32px 0 32px;
  }
  @media screen and (max-width: 350px) {
      padding: 0 10px 0 10px;
  }
`
const ArrowPrevIcon = styled.img`
  transform:rotate(180deg);
  @media screen and (max-width: 600px) {
    width: 15px;
  }
  :disabled {
    visibility: hidden;
  }
`
const ArrowNextIcon = styled.img`
  @media screen and (max-width: 600px) {
    width: 15px;
  }
 :disabled {
    visibility: hidden;
  }
`

const ArrowButton = styled.button`
  background-color: transparent;
  border-width: 0px;
  :disabled {
    visibility: hidden;
  }
`

const breakPoints: ReactElasticCarouselProps['breakPoints'] = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 520, itemsToShow: 2, itemsToScroll: 1 },
  { width: 800, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1100, itemsToShow: 4, itemsToScroll: 1 },
  { width: 1300, itemsToShow: 5, itemsToScroll: 1 },
  { width: 1500, itemsToShow: 6, itemsToScroll: 1 }
];

const LastItem = {
  id: 'openStoreButton',
  type: 'openStoreButton'
}

export default () => {
  const { getTextWithSiteType, isBusinessSiteType } = useContext(UserContext)
  const [touchpoints, setTouchoints] = useState<IServTouchpoint[]>([])

  useEffect(() => {
    getTouchpoints(isBusinessSiteType).then((res) => {
      if (res) {
        setTouchoints(res)
      }
    })
  }, [])

  const onClickHandler = useCallback((tp: IServTouchpoint) => {
    //@ts-ignore
    window.open(tp.apply_url);
  }, [])

  return (
    <>
      <TextWrapper>
        <Title>{getTextWithSiteType('scenes.scene-4.title')}</Title>
      </TextWrapper>
      <Container>
        <TouchpointsRow>
          <Carousel
            breakPoints={breakPoints}
            renderArrow={CustomArrows}
            renderPagination={() => <div />}
          >
            {[...touchpoints, LastItem].map((tp, index) => {
              return (
                <TocuhpointWrapper key={tp.id}>
                  <Touchpoint
                    onClick={onClickHandler}
                    touchpoint={tp}
                  />
                  {index < touchpoints.length ?
                    <TocuhpointSpace />
                    : null
                  }
                </TocuhpointWrapper>
              )
            })}
          </Carousel>
        </TouchpointsRow>
      </Container>
    </>
  )
}

interface CustomArrowsProps {
  type: 'PREV' | 'NEXT'
  onClick: () => void
  isEdge: boolean
}




const CustomArrows: React.FC<CustomArrowsProps> = ({ type, onClick, isEdge }) => {

  const pointer = type === 'PREV' ?
    <ArrowButton disabled={isEdge}>
      <ArrowPrevIcon src={RightIcon} />
    </ArrowButton>
    :
    <ArrowButton disabled={isEdge}>
      <ArrowNextIcon src={RightIcon} />
    </ArrowButton>

  const onClickHandler = () => {
    if (isEdge) {
      return
    }
    onClick()
  }
  return (
    <div
      style={{
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      onClick={onClickHandler} >
      {pointer}
    </div>
  )
}