import React from 'react'
import styled from 'styled-components';
import img1 from '../../public/reviews-slider/comment_1.webp';
import img2 from '../../public/reviews-slider/comment_2.webp';
import img3 from '../../public/reviews-slider/comment_3.webp';
import img4 from '../../public/reviews-slider/comment_4.webp';
import img5 from '../../public/reviews-slider/comment_5.webp';

const images = [img1, img2, img3, img4, img5];

const Container = styled.div`
  width: 100%;
  border-radius: 32px;
  background-color: #f5f5f5;
`

const CommentsRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  column-gap: 32px;
  padding: 60px;

  @media screen and (max-width: 1080px) {
    padding: 60px 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const Comment = styled.img`
  object-fit: contain;
  box-shadow: 0px 20px 40px 0px rgba(177, 177, 177, 0.20);
  border-radius: 12px;
  width: 430px;
  max-width: 100%;
`

export const CommentsSection = () => {
  return (
    <Container>
      <CommentsRow>
        {images.map((image) => (<Comment key={image} src={image} />))}
      </CommentsRow>
    </Container>
  )
}
