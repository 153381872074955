import Cookie from 'js-cookie'
import { defaultLanguage, languages } from '../i18n'


const COOKIE_LANG_KEY = '_intch_lang_new'

export const getLanguage = () => {
  const savedLanguage = Cookie.get(COOKIE_LANG_KEY)
  if (savedLanguage) {

    return savedLanguage
  } else {
    const browserLanguage = window.navigator.language.split('-')[0]

    const fallbacks: { [key: string]: string } = {
      //Kazakh fallback
      'kk': 'ru',
    }

    const fallback = fallbacks[browserLanguage] || defaultLanguage


    const lang = languages[browserLanguage as keyof typeof languages]?.short ?? fallback

    setLanguage(lang)
    return lang
  }
}


export const setLanguage = (lang: string) => {
  Cookie.set(COOKIE_LANG_KEY, lang, { expires: 365 * 5 })
}