import React from 'react';
import styled from 'styled-components';

import devices from '../devices';

import { useTranslation } from 'react-i18next';
import { CommentsSection } from '../components/CommentsSection';

const Card = styled.div`
    display: flex;
    width: 515px;
    position: relative;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(119.72deg, #FFFFFF 14.87%, #F9FBFE 107.34%);
    box-shadow: 0px 2px 8px rgba(10, 107, 255, 0.1), 0px 16px 40px rgba(10, 107, 255, 0.03), 0px 36px 86px rgba(111, 151, 214, 0.06);
    flex-direction: row;

    @media ${devices.laptop} {
        width: 100%;
        flex-direction: column;
    }
`

const Person = styled.img`
    width: 180px;
    height: 316px;
    object-fit: cover;

    @media ${devices.laptop} {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: 32px;
    }
`

const RoundPerson = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
    margin: 36px 0 0 37px;

    @media ${devices.laptop} {
        margin: 32px auto 0 auto;
    }
`

const HeaderTitle = styled.h2`
    font-size: 34px;
    font-weight: 600;
    line-height: 47px;
    max-width: 800px;
    text-align: center;
    margin-bottom: 60px;

    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 40px;
    }
`
const Title = styled.div`
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    margin-top: 32px;
    margin-left: 20px;

    @media ${devices.laptop} {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        margin-left: 0;
    }
`

const Content = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    color: #545D6C;
    padding: 21px 36px 36px 20px;

    @media ${devices.laptop} {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        padding-bottom: 32px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 32px;
    max-width: 1100px;
`

export default () => {
    const [t] = useTranslation('translations');

    return (
        <Container>
            <HeaderTitle>{t('scenes.scene-5.title')}</HeaderTitle>
            <CommentsSection />
        </Container>
    )
}
