import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import devices from '../devices';


const Background = styled.div`
    background: #ffffff;
    min-height: 67px;
    display: flex;
    align-items: center;
`

const ItemsLeft = styled.div`
    font-size: 13px;
    font-weight: 500;
    flex-grow: 1;

    @media (max-width: 600px) {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    a {
        color: #6987D7;
        font-weight: 500;
        text-decoration: none;
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            opacity: .7;
        }    

        @media ${devices.laptop} {
            margin-right: 16px;
            margin-bottom: 4px;
        }
    }
`

const ItemsRight = styled.div`
    text-align: center;
    padding-left: 10px;
    * {
        color: #818FA4;
        font-weight: 500;
    }
    a {
        text-decoration: none;

        &:hover {
            opacity: .7;
        }    
    }
`

const Container = styled.div`
    justify-content: center;
    flex-wrap: wrap;
    line-height: 2;
    width: 100%;
`

const Link = styled.a`
    white-space: nowrap;
    min-width: 0;
`

export default () => {
    const year = new Date().getFullYear();
    const [t] = useTranslation('translations');


    return (
        <Background>
            <Container className="container">
                <ItemsLeft>
                    <Link href="https://intch.org/legal/User_agreement.pdf">{t('buttons.agreement')}</Link>
                    <Link href="https://intch.org/legal/Privacy_policy.pdf">{t('buttons.policy')}</Link>
                    <a
                        href="#" onClick={window?.displayPreferenceModal}
                        id="termly-consent-preferences">
                        {t("buttons.cookie_settings", "Cookie Settings")}
                    </a>
                    <Link href="https://intch.org/blog">{t('buttons.our_blog', 'Our blog')}</Link>
                    <Link href="https://intch.org/contact-us">{t('buttons.our_blog', 'Contacts')}</Link>
                    <Link target={'_blank'} href="https://miro.com/app/board/uXjVNh8Im3A=/">{t('buttons.intch_reviews', 'Intch Reviews')}</Link>
                </ItemsLeft>
                <ItemsRight>
                    <span>Intch {year}</span>
                </ItemsRight>
            </Container>
        </Background >
    )
}
