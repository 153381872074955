import { get } from 'lodash'
import UAParser from 'ua-parser-js'

const parser = new UAParser()

export const isMobile = () => parser.getDevice().type === 'mobile'
export const getOS = () => parser.getOS().name
export const getBrowser = () => parser.getBrowser()

export const isSafari = () =>
  get(getBrowser(), 'name', '').toLowerCase().includes('safari')
export const isChromium = () =>
  typeof window !== 'undefined'
    ? navigator.vendor === 'Google Inc.'
    : true
