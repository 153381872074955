import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Avatar } from '../Avatar';
import { useTranslation } from 'react-i18next';
import { RightIcon } from '../RightIcon';
import { MoreIcon } from '../MoreIcon';
import { IServTouchpoint } from '../../utils/touchpoints';
import { Statistics } from './components/Statistics';
import { SlideLines } from './components/SlideLines';
import { OpenStoreListItem } from './components/OpenStoreListItem';
import { isSafari } from '../../utils/device';


export const getInitialsHandler = (profile:{first_name:string,last_name:string}) => {
  let str = ''
  if(profile.first_name){
    str += profile.first_name[0]
  }
  if(profile.last_name){
    str += profile.last_name[0]
  }
  return str.toUpperCase()
}
const concatJob = ({company,job_title}:{company:string,job_title:string}) => {
  let str = ''
  if(job_title){
    str += job_title
  }
  if(company){
    str += ` @${company}`
  }
  return str
}

const concatName = ({first_name,last_name}:{first_name:string,last_name:string}) => {
  const str = (first_name ? `${first_name} ` : '') + (last_name  ?? '') 
  return str
}

export const TouchpointBackground = styled.img`
    height: 338px;
    width: 100%;
    position: absolute;
    flex-shrink: 0;
    @media screen and (max-width: 600px) {
      height: 318px;
  }
`
export const TouchpointContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 372px;
    width: 235px;
    border: 0.7px solid #E2E6EB;
    box-shadow: 0px 5.6px 8.4px rgba(62, 79, 143, 0.1);
    border-radius: 8.40364px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      width: 215px;
      height: 352px;
  }
`

const Name = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    margin-top: 16px;
    -webkit-line-clamp: 1; /* Число отображаемых строк */
     -webkit-box-orient: vertical; /* Вертикальная ориентация */
    display: -webkit-box; /* Включаем флексбоксы */
    overflow: hidden;  
    width: 100%;
    text-align: center;
`

const JobPosition = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 120%;
    opacity: 0.5;
    text-align: center;
    padding: 0 10px 0 10px;
    -webkit-line-clamp: 1; /* Число отображаемых строк */
     -webkit-box-orient: vertical; /* Вертикальная ориентация */
    display: -webkit-box; /* Включаем флексбоксы */
    overflow: hidden;  
    width: 100%;
    text-align: center;
`
const Category = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 17px;
`

const Title = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14.0061px;
    line-height: 130%;
    text-align: center;
    margin-top: 22px;
    word-wrap:break-word;
    width: 100%;
    white-space: pre-wrap;
    -webkit-line-clamp: 4; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; 
    text-align: center;
`

const ContentWrapper = styled.div`
  padding: 36px 22px 22px 22px;
  align-items: center;
  width:100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const Button = styled.div`
    display: flex;
    height: 33.5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(11.2049px);
    border-radius: 8px;
    cursor:default;
    margin-top: auto;
`
export const ButtonTitle = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
`


const MoreIconWrapper = styled.div`
  position:absolute;
  height: 14;
  aspect-ratio: 1;
  right: 8.5px;
  top: 8.5px;
`


const RightArrowWrapper = styled.div`
  position:absolute;
  height: 14;
  align-self: flex-end;
  top: 50%;
  right: 3px;
`

const avatarStyle = { width: 42, height: 42 }

interface TouchpointProps {
  touchpoint: IServTouchpoint & { type?: string }
  onClick: (tp: IServTouchpoint) => void
}

export const Touchpoint: React.FC<TouchpointProps> = React.memo((
  { touchpoint, onClick }
) => {
  const [t] = useTranslation('translations');
  const textColorStyle = useMemo(() => ({
    color: touchpoint?.config?.theme?.fontColor
  }), [touchpoint])
  const profile = touchpoint?.profile
  const config = touchpoint?.config


  const onClickHandler = () => {
    onClick(touchpoint)
  }

  if (touchpoint?.type === 'openStoreButton') {
    return <OpenStoreListItem />
  }

  return (
    <TouchpointContainer onClick={onClickHandler}>
      <TouchpointBackground src={config.theme.webBackground?.url || config.theme.background?.url} />
      <MoreIconWrapper>
        <MoreIcon stroke={config.theme.indicatorActiveColor} />
      </MoreIconWrapper>
      <ContentWrapper>
        <RightArrowWrapper>
          <RightIcon fill={config.theme.indicatorActiveColor} />
        </RightArrowWrapper>
        <Avatar 
          textPosY={isSafari() ? 0.6 : 0.5}
          src={touchpoint.profile.avatar_url} 
          initials={getInitialsHandler(profile)}
          style={avatarStyle} 
          />
        <Name style={textColorStyle}>{concatName(profile)}</Name>
        <JobPosition style={textColorStyle}>{concatJob(profile)}</JobPosition>
        <Category style={textColorStyle}>{t(touchpoint?.category?.key)}</Category>
        <Title 
          style={textColorStyle}
        >
          {touchpoint.config.title}
        </Title>
        <Button style={textColorStyle}>
          <ButtonTitle>{t('buttons.reply','Reply')}</ButtonTitle>
        </Button>
      </ContentWrapper>
      <SlideLines bgColor={touchpoint.config.theme.indicatorActiveColor} />
      <Statistics
        previews={touchpoint.stats.entries.preview}
        appliedCount={touchpoint.stats.entries.count}
        viewedCount={touchpoint.stats.views.total} />
    </TouchpointContainer>
  )
})

