import styled, { CSSProperties } from 'styled-components'

const AvatarWrapper = styled.div`
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    img {
        width: 100%;
        height: 100%;
    }
`

const AvatarClipPaths = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath
        id="avatarVectorClip"
        clipPathUnits="objectBoundingBox"
      >
        <use href="#avatarClipPath" />
      </clipPath>
    </defs>
    <path
      id="avatarClipPath"
      d="M0.3790625 0.0362848125C0.45389875 -0.0037616125 0.54610125 -0.0037616125 0.6209375 0.0362848125L0.8728125 0.171068125C0.94765 0.211115 0.99375 0.28512375 0.99375 0.36521625V0.63478125C0.99375 0.714875 0.94765 0.7888875 0.8728125 0.82893125L0.6209375 0.9637125C0.54610125 1.0037625 0.45389875 1.0037625 0.3790625 0.9637125L0.1271875 0.82893125C0.052351125 0.7888875 0.00625 0.714875 0.00625 0.63478125V0.36521625C0.00625 0.28512375 0.0523510625 0.211115 0.1271875 0.171068125L0.3790625 0.0362848125Z"
    />
    <path
      id="avatarOutlinePath"
      d="M0.38595714285714 0.046159642857143C0.45647678571429 0.0084229821428571 0.54352321428571 0.0084229821428571 0.61404285714286 0.046159642857143L0.86910714285714 0.18265C0.93951428571429 0.22032678571429 0.98214285714286 0.28946428571429 0.98214285714286 0.36351071428571V0.63648928571429C0.98214285714286 0.71053571428571 0.93951428571429 0.77967321428571 0.86910714285714 0.81735L0.61404285714286 0.95384107142857C0.54352321428571 0.99157678571429 0.45647678571429 0.99157678571429 0.38595714285714 0.95384107142857L0.13089357142857 0.81735C0.060485535714286 0.77967321428571 0.017857142857143 0.71053571428571 0.017857142857143 0.63648928571429V0.36351071428571C0.017857142857143 0.28946428571429 0.060485357142857 0.22032678571429 0.13089357142857 0.18265L0.38595714285714 0.046159642857143Z"
    />
    <svg id="avatarOutline" viewBox="0 0 1 1">
      <use href="#avatarOutlinePath" />
    </svg>
  </svg>
)

interface AvatarProps {
  src?:string
  initials?:string
  style?:CSSProperties
  className?:string,
  textPosY?:number
}

export const Avatar:React.FC<AvatarProps> = ({
  src,
  initials,
  style,
  className,
  textPosY = 0.5
}) => {
  return (
    <AvatarWrapper style={style} className={className}>
      <svg width="100%" height="100%" viewBox="0 0 1 1" fill='#818FA4'>
        <AvatarClipPaths />
        {src ? (
          <image
            width={'100%'}
            height={'100%'}
            x={'0'}
            y={'0'}
            href={src}
            style={{ clipPath: 'url(#avatarVectorClip)' }}
          />
        ) : (
          <>
            <use
              href="#avatarOutline"
              width={'100%'}
              height={'100%'}
              x={0}
              y={0}
              fill="var(--bi-around-text)"
            />
            <text
              x="0.5"
              y={textPosY}
              fill="#E2E6EB"
              fontSize="0.36"
              fontWeight="700"
              textAnchor="middle"
              dominantBaseline="central"
            >
              {initials}
            </text>
          </>
        )}
      </svg>
    </AvatarWrapper>
  )
}
