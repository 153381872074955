import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'react-responsive';
import devices from '../devices';

import FigureImg from '../../public/top-header-figure.png';
import FigureImgMobile from '../../public/top-header-figure-mobile.png';
import logo from '../../public/logo.svg';
import loginSvg from '../../public/login.svg';
import { Avatar } from './Avatar';
import { useContext } from 'react';
import { UserContext } from '../userContext';
import { getInitials } from '../utils/contacts';
import { useTranslation } from 'react-i18next';
import { scrollToLinks } from '../utils/scrollToLinks';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';

const Logo = styled.img`
    width: 94px;
`

const LoginSvg = styled.img`
    margin-right: 6px;
`
const MenuContainer = styled.div`
    padding-top: 25px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    
    @media ${devices.mobile} {
        padding-top: 24px;
        padding-bottom: 20px;
        flex-direction: column;
    }
`

const RightMenu = styled.div`
    /* margin-left: auto; */
    display: flex;
    align-items: center;
    z-index: 2;
    gap:8px;
    @media ${devices.mobile} {
        margin-left:0;
        margin-top: 20px;
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 100%;
    }
`

const Figure = styled.div`
    position: absolute;
    left: 661px;
    // z-index: -1;
    @media ${devices.laptop} {
        display: none;
    }
`

const FigureMobile = styled.div`
    position: absolute;
    // z-index: -1;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    img { 
        object-fit: fill;
        height: 100%;
        max-height: 778px;
        width: 100%;
    }

    @media ${devices.laptop} {
        display: block;
    }
`

const MenuBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px 0;
    z-index: 1000;
    overflow: hidden;

    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        /* backdrop-filter: blur(10px); */
    }

    @media ${devices.laptop} {
        padding-top: 16px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items:center;
    @media ${devices.mobile} {
        width: 100%;
    }
`

const WhiteButton = styled.a<{ height?: number }>`
    height: ${({ height }) => height ? `${height}px` : '46px'};
    display: flex;
    height: 46px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    background-color: ${({ mobile }) => mobile ? '#F2F4F6' : 'white'};
    padding-top: ${({ mobile }) => mobile ? '12px' : '6px'};
    padding-bottom: ${({ mobile }) => mobile ? '12px' : '6px'};
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 12px;
    margin-right: 8px;

    @media ${devices.mobile} {
        width: 100%;
    }
`

const Text = styled.span`
    white-space: nowrap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: black;
`


const Badge = styled.div`
    margin-left: 8px;
    background-color: #FF4343;
    box-shadow: 0px 8px 12px rgba(184, 11, 11, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    min-width: 20px;
`

const BadgeText = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: white;
`

const LogoContainer = styled.div`
    width: 94px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items:flex-end;
    @media ${devices.mobile} {
        flex-direction: column;
        align-items: center;
    }
`

const Title = styled.span`
    white-space: nowrap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #818FA4;
    margin-left: 24px;
    @media ${devices.laptop} {
        font-size: 12px;
    }
    @media ${devices.mobile} {
        margin-left: 0;
        margin-top: 10px;
    }
`


const AuthBtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    max-width: 360px;
    margin: 0 auto;

    @media ${devices.mobile} {
        width: 100%;
        max-width: 100%;
    }
`;

export const AuthBtn = styled.button<{ bgColor?: string }>`
    font-family: Montserrat;
    height: 46px;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.color || 'white'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: ${props => props.bgColor || 'black'};
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid #F2F4F6;
    box-shadow: rgba(94, 94, 94, 0.06) 0px 4px 8px 0px;

    @media ${devices.mobile} {
        width: 100%;
    }
`

const UserButtons = ({ chatUnreadCounter, profile }) => {
    return (
        <Row>
            <WhiteButton height={56} href='/chats'>
                <Text>My Dashboard</Text>
                {
                    chatUnreadCounter ? (
                        <Badge>
                            <BadgeText>{chatUnreadCounter}</BadgeText>
                        </Badge>
                    ) : null
                }
            </WhiteButton>
            <WhiteButton height={46} href={'profile/my'}>
                <Avatar src={profile.avatar_url} style={{ marginRight: 8 }} initials={getInitials(profile)} />
                <Text>{profile.display_name || profile.phone}</Text>
            </WhiteButton>
        </Row>
    )
}

const MenuComponent = () => {
    const { t } = useTranslation()

    const { currentLanguage, setCurrentLanguage } = useContext(UserContext)


    const {
        chatUnreadCounter,
        profile,
        isAuthenticated,
    } = useContext(UserContext)


    const urlParams = new URLSearchParams({
        utm_source: 'intch_main',
    });


    const handleSignIn = () => {
        urlParams.append('utm_campaign', 'login');
        window.location.assign(`/email-auth?${urlParams.toString()}`)
    };


    return (
        <>
            <MenuContainer>
                <LogoContainer>
                    <Logo src={logo} />
                    <Title>{t('Future of Work App')}</Title>
                </LogoContainer>
                <RightMenu>
                    <LanguageSelector selectedLanguage={currentLanguage} onSelectLanguage={setCurrentLanguage} />
                    {
                        isAuthenticated
                            ?
                            <UserButtons
                                profile={profile}
                                chatUnreadCounter={chatUnreadCounter}
                            />
                            : (
                                <AuthBtnWrapper>
                                    <AuthBtn onClick={handleSignIn} bgColor="white" color="#000000">
                                        <LoginSvg src={loginSvg} />
                                        {t('buttons.signIn', "Sign In")}
                                    </AuthBtn>
                                    <AuthBtn onClick={scrollToLinks}>
                                        {t('buttons.signUp', "Sign Up")}
                                    </AuthBtn>
                                </AuthBtnWrapper>
                            )}

                </RightMenu>
            </MenuContainer>
        </>
    )
}

export default () => {
    const [showMenu, setMenu] = useState(false);
    const isMobile = useMediaQuery({ query: devices.laptop });

    const trackScrolling = () => {
        if (window.scrollY > 10) {
            !showMenu && setMenu(true);
        }
        if (window.scrollY == 0) {
            showMenu && setMenu(false);
        }
    }


    useEffect(() => {
        trackScrolling();
        document.addEventListener('scroll', trackScrolling);
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    })

    const Menu = useMemo(() => <MenuComponent />, [])

    return (
        <>
            <div className="container">
                <Figure>
                    <img src={FigureImg} />
                </Figure>
                <FigureMobile>
                    <img src={FigureImgMobile} />
                </FigureMobile>
                {!isMobile ?
                    <div>
                        <CSSTransition
                            classNames="MenuBackground"
                            timeout={300}
                            unmountOnExit
                            in={showMenu}>
                            <MenuBackground>
                                <div className="container">
                                    {Menu}
                                </div>
                            </MenuBackground>
                        </CSSTransition>
                    </div>
                    : null
                }
                {Menu}
            </div>
        </>
    )
}
