import React from 'react';
import styled from 'styled-components';
import { Row } from '../../common';

interface SlideLinesProps {
  bgColor: string
}
interface SlideLineProps {
  active?: boolean,
  mr?: number,
  ml?: number,
  bgColor: string
}

const SlideLineComponent = styled.div`
  display: flex;
  flex:1;
`

export const SlideLines: React.FC<SlideLinesProps> = React.memo(({ bgColor }) => {
  return (
    <Row className='touchpoint_lines_row'>
      <SlideLine bgColor={bgColor} mr={2.8} active={true} />
      <SlideLine bgColor={bgColor} />
      <SlideLine bgColor={bgColor} ml={2.8} />
    </Row>
  )
})

const SlideLine: React.FC<SlideLineProps> = ({ active, mr, ml, bgColor }) => {
  return (
    <SlideLineComponent style={{
      opacity: active ? 0.9 : 0.4,
      marginLeft: ml,
      marginRight: mr,
      backgroundColor: bgColor,
    }} />
  )
}
