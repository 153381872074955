
import React, { Fragment } from 'react'
import { Popover } from 'react-tiny-popover'
import styled from 'styled-components'
import { isMobile } from '../../../utils/device'

import SVGCheckBlack from '../../../../public/CheckBlack.svg'
import { TLanguagesArray } from '../../../i18n'

const popoverProps: Partial<CSSStyleDeclaration> = {
  zIndex: '1000',
  top: isMobile() ? "0px" : '30px',
  backgroundColor: 'white',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  borderColor: '#E2E6EB',
  borderStyle: 'solid',
  filter: 'drop-shadow(0px 8px 8px rgba(129, 143, 164, 0.10))',
  width: '200px',
  padding: '16px',
}


const LanguageList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const LanguageListItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    user-select: none;
    cursor: pointer;
`

const LanguageListItemLocalTitle = styled.span`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
`
const LanguageListItemTitle = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #818fa4;
    line-height: 16px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f4f6;
  margin: 12px 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  `

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  `




interface IProps {
  isOpenedPopover: boolean
  availableLanguages: TLanguagesArray
  selectedLanguage: string
  onClosePopover: () => void
  onSelectLanguage: (language: string) => void
}

export const LanguagesPopover: React.FC<IProps> = ({
  availableLanguages,
  isOpenedPopover,
  selectedLanguage,
  onClosePopover,
  onSelectLanguage,
}) => {
  return (
    <Popover
      align={isMobile() ? 'center' : 'end'}
      isOpen={isOpenedPopover}
      onClickOutside={onClosePopover}
      containerStyle={popoverProps}
      positions={['bottom']}
      content={() => {
        return (
          <LanguageList>
            {availableLanguages.map((language, index) => (
              <Fragment key={index}>
                <LanguageListItem onClick={() => onSelectLanguage(language.short)}>
                  <Row>
                    <Column>
                      <LanguageListItemLocalTitle>
                        {language.localTitle}
                      </LanguageListItemLocalTitle>
                      <LanguageListItemTitle>
                        {language.title}
                      </LanguageListItemTitle>
                    </Column>
                    {language.short === selectedLanguage && <img src={SVGCheckBlack} />}
                  </Row>
                </LanguageListItem>
                {index === 0 && <Divider />}
              </Fragment>
            ))}
          </LanguageList>
        )
      }}
    >
      <div />
    </Popover>
  )
}
