
import Cookie from 'js-cookie'

class ApiClient {
  baseUrl = process.env.RAZZLE_API_URL

  getAccess = () => {
    return Cookie.get('access')
  }

  getRefresh = () => {
    return Cookie.get('refresh')
  }

  checkNetwork = async (
    cb: () => Promise<Response>,
    url: string,
  ): Promise<Response> => {
    try {
      const response = await cb()

      const ok = response.status === 200 || response.status === 204
      if (ok) {
        return Promise.resolve(response)
      }

      if (response.status === 401) {
        try {
          const refreshToken = this.getRefresh()
          if (refreshToken) {
            await this.refreshToken()
            return this.checkNetwork(cb, url)
          } else {
            this.logout()
            return Promise.reject()
          }
        } catch (e) {
          return Promise.reject(e)
        }
      }

      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  refreshToken = async () => {
    const refresh = this.getRefresh()
    const res = await fetch(`${this.baseUrl}/auth/token/refresh/`, {
      method: 'POST',
      body: JSON.stringify({ refresh }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!res.ok) {
      this.logout()
      return Promise.reject(res)
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = await res.json()

    Cookie.set('refresh', data.refresh, { expires: 365 * 5 })
    Cookie.set('access', data.access, { expires: 365 * 5 })

    return Promise.resolve(data)
  }

  logout = () => {
    Cookie.remove('access')
    Cookie.remove('refresh')
  }

  getNoAuth = (url: string) => fetch(`${this.baseUrl}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })

  get = (url: string): Promise<Response> =>
    this.checkNetwork(
      () =>
        fetch(`${this.baseUrl}${url}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${this.getAccess()}`
          },
        }),
      `${this.baseUrl}${url}`,
    )

  post = (url: string, params?: any): Promise<Response> =>
    this.checkNetwork(
      () =>
        fetch(`${this.baseUrl}${url}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            ...(this.getAccess()
              ? { Authorization: `Bearer ${this.getAccess()}` }
              : {}),
          },
          body: JSON.stringify(params),
        }),
      `${this.baseUrl}${url}`,
    )
}

export const apiClient = new ApiClient()