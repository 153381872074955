
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend';

import en from '../public/locales/en/translations.json'
import pt from '../public/locales/pt/translations.json'
import ru from '../public/locales/ru/translations.json'
import de from '../public/locales/de/translations.json'


export type TLanguagesArray = typeof languages[keyof typeof languages][]



export const languages = {
  en: {
    title: 'English',
    localTitle: 'English',
    short: 'en',
    locale: en,
  },
  pt: {
    title: 'Portuguese',
    localTitle: 'Português',
    short: 'pt',
    locale: pt,
  },
  de: {
    title: 'German',
    localTitle: 'Deutsch',
    short: 'de',
    locale: de,
  },
  ru: {
    title: 'Russian',
    localTitle: 'Русский',
    short: 'ru',
    locale: ru,
  },
}

const options: InitOptions =
{
  lng: 'en',
  ns: ['translations'],
  defaultNS: 'translations',
  returnNull: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};


// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
}







export const languagesArray: TLanguagesArray = Object.values(languages)

export const defaultLanguage = languages.en.short


// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}


export default i18n;