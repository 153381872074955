import React, { useContext } from 'react';
import styled from 'styled-components';

import devices from '../devices';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../userContext';


const Title = styled.h2`
    font-size: 34px;
    font-weight: 600;
    line-height: 47px;
    max-width: 800px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 120px;

    @media ${devices.laptop} {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 32px;
        margin-top: 80px;
    }
`

const Container = styled.div`
    @media ${devices.laptop} {
        overflow: hidden;
        overflow-x: scroll;
        padding: 0 !important;
    }
`

const StepData = styled.div`
    display: flex;
    padding: 0 40px;
    @media ${devices.laptop} {
        flex-direction: column;
        margin-right: 40px;
        flex-grow: 1;
        padding: 0;
    }
`
const StepTitle = styled.h3`
    white-space: pre-wrap;
    font-size: 28px;
    line-height: 37px;
    font-weight: 700;
    margin-bottom: 20px;

    @media ${devices.laptop} {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 12px;
    }
`

const StepDescription = styled.p`
    white-space: pre-wrap;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
    @media ${devices.laptop} {
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 24px;
    }
`

const StepsContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    @media ${devices.laptop} {
        flex-direction: row;
        margin-bottom: -80px;
        flex-wrap: nowrap;
        align-items: unset;
    }
`

const StepContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 52px;
    &.reverse {
        flex-direction: row-reverse;
    }
    @media ${devices.laptop} {
      flex-direction: column !important;
      justify-content: space-between;
      margin-right: 130px;
      min-width: 257px;
      margin-left: 40px;
      &:nth-child(5n) {
        /* margin-left: 20px; */
      }
    }
`

const Step = styled.div`
    position: absolute;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #6987D7;
    @media ${devices.laptop} {
      font-size: 14px;
    }
`

const Image = styled.img`
    height: 500px;
    @media ${devices.laptop} {
        margin-bottom:30px;
        margin-right: 12px;
    }
`



const Gexagon = ({ children, style, size = 60 }) => {
  return (
    <div style={{ ...style, position: 'relative', }}>
      <Step style={{ width: size, height: size }}>{children}</Step>
      <svg width={size} height={size} viewBox={`0 0 60 60`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8878 2.26575C27.2874 -0.0885829 32.7126 -0.0885834 37.1122 2.26575L52.416 10.4551C56.8125 12.8078 59.5 17.1419 59.5 21.8106V38.1894C59.5 42.8581 56.8125 47.1922 52.416 49.5449L37.1122 57.7343C32.7126 60.0886 27.2874 60.0886 22.8878 57.7343L7.584 49.5449C3.1875 47.1922 0.5 42.8581 0.5 38.1894V21.8106C0.5 17.1419 3.1875 12.8078 7.584 10.4551L22.8878 2.26575Z" fill="#F1F7FF" />
      </svg>
    </div >
  )
}

const StepComp = ({ img, img_business, description, description_business, title, title_business, style, className, index }) => {
  const isMobile = useMediaQuery({ query: devices.laptop });

  const { isBusinessSiteType } = useContext(UserContext)

  return (
    <StepContainer style={style} className={className}>
      <StepData>
        <Gexagon size={isMobile ? 40 : 60}
          style={isMobile ? { marginBottom: 14 } : { marginRight: 35 }}
        >{index + 1}</Gexagon>
        <div style={{ width: isMobile ? undefined : 392 }}>
          <StepTitle>{isBusinessSiteType ? title_business : title}</StepTitle>
          <StepDescription>
            {isBusinessSiteType ? description_business : description}
          </StepDescription>
        </div>
      </StepData>
      <Image src={require(`../../public/${isBusinessSiteType ? img_business : img}`)} />
    </StepContainer>
  )
}

export default () => {
  const isMobile = useMediaQuery({ query: devices.laptop });
  const [t] = useTranslation('translations');
  const cases = t('scenes.howItWorks.cases', { returnObjects: true })
  const { isBusinessSiteType } = useContext(UserContext)
  return (
    <>
      <div className="container container-mobile h-middle">
        <Title>{t('scenes.howItWorks.title')}</Title>
      </div>
      <Container className="container container-mobile h-middle">
        <StepsContainer>
          {
            cases.map((val, i) => {
              if (!isBusinessSiteType && i > 3) {
                return
              }
              return (
                <StepComp
                  {...val}
                  key={i}
                  index={i}
                  className={i % 2 ? 'reverse' : ''}
                  style={{ marginTop: isMobile ? 0 : val.top || 0, marginRight: 0 }}
                />
              )
            })
          }
        </StepsContainer>
      </Container>
    </>
  )
}