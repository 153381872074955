
import { useRef, useState } from 'react'
import React from 'react'
import styled from 'styled-components'
import SVGLanguage from '../../../public/language.svg'
import { LanguagesPopover } from './LanguagesPopover/LanguagesPopover'
import { languages, TLanguagesArray } from '../../i18n'


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  border: 1 solid #e2e6eb;
  padding: 6px 20px;
  column-gap: 4xp;
  user-select: none;
  cursor: pointer;
  height: 46px;
  box-shadow: rgba(94, 94, 94, 0.06) 0px 4px 8px 0px;
`

const LanguageName = styled.span`
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  margin-left: 4px;
  text-transform: capitalize;
`



export const languagesArray: TLanguagesArray = Object.values(languages)

interface IProps {
  selectedLanguage: string
  onSelectLanguage: (language: string) => void
}

export const LanguageSelector: React.FC<IProps> = ({ selectedLanguage, onSelectLanguage }) => {
  const anchorEl = useRef<HTMLDivElement>(null)
  const [isOpenedPopover, setIsOpenedPopover] = useState(false)

  const onOpenPopover = () => {
    setIsOpenedPopover(true)
  }

  const onClosePopover = () => {
    setIsOpenedPopover(false)
  }

  const onSelectLanguageHandler = (language: string) => {
    onSelectLanguage(language)
    onClosePopover()
  }

  return (
    <>
      <Container ref={anchorEl} onClick={onOpenPopover}>
        <img src={SVGLanguage} />
        <LanguageName>
          {selectedLanguage}
        </LanguageName>
      </Container>
      <LanguagesPopover
        selectedLanguage={selectedLanguage}
        availableLanguages={languagesArray}
        isOpenedPopover={isOpenedPopover}
        onClosePopover={onClosePopover}
        onSelectLanguage={onSelectLanguageHandler}
      />
    </>
  )
}
