import styled from "styled-components";
import { GoalCard } from "../components/GoalCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { scrollTargetId } from "../utils/scrollToLinks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px auto;
  width: 675px;
  @media (max-width: 675px) {
    width: 100%;
    margin: 80px auto;
  }
`;

const Title = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 140%;
  @media (max-width: 675px) {
    font-size: 20px;
    line-height: 150%;
  }
`;
const Description = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  color: #545d6c;

  margin-top: 16px;

  @media (max-width: 675px) {
    font-size: 15px;
    line-height: 170%;
    padding: 0 32px;
  }
`;

const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 12px;
  row-gap: 12px;
  margin-top: 60px;
  @media (max-width: 675px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding:0 32px 20px;
    margin-top: 40px;
    justify-content: flex-start;
  }
`;

const cards = [
  {
    emoji: "📝",
    title_key: "scenes.scene-2.cards.1.title",
    subtitle_key: "scenes.scene-2.cards.1.description",
    backgroundColor: "#C6F5FF",
    url: "https://intch.org/onboarding/professional?utm_source=intch_main&utm_medium=intch_help",
  },
  {
    emoji: "👋",
    title_key: "scenes.scene-2.cards.2.title",
    subtitle_key: "scenes.scene-2.cards.2.description",
    backgroundColor: "#FFDBF5",
    url: "https://intch.org/onboarding/marketing?utm_source=intch_main&utm_medium=intch_help",
  },
  {
    emoji: "💸",
    title_key: "scenes.scene-2.cards.3.title",
    subtitle_key: "scenes.scene-2.cards.3.description",
    backgroundColor: "#F0E9DE",
    url: "https://intch.org/onboarding/developers?utm_source=intch_main&utm_medium=intch_help  ",
  },
  {
    emoji: "🚀",
    title_key: "scenes.scene-2.cards.4.title",
    subtitle_key: "scenes.scene-2.cards.4.description",
    backgroundColor: "#E5E8FF",
    url: "https://intch.org/onboarding/designers?utm_source=intch_main&utm_medium=intch_help",
  },
  {
    emoji: "😎",
    title_key: "scenes.scene-2.cards.5.title",
    subtitle_key: "scenes.scene-2.cards.5.description",
    backgroundColor: "#FFEBBB",
    url: "https://intch.org/recruiter?utm_source=intch_main&utm_medium=intch_help",
  },
];

export const Scene_2 = () => {
  const [t] = useTranslation('translations');

  return (
    <Container>
      <Title>{t("scenes.scene-2.title", "How Intch can help you")}</Title>
      <Description>
        {t(
          "scenes.scene-2.description",
          "Intch has helped over 500,000 professionals and 50,000+ businesses find each other"
        )}
      </Description>
      <CardsWrapper id={scrollTargetId}>
        {cards.map((card, index) => {
          return (
            <GoalCard
              key={index}
              {...card}
              title={t(card.title_key)}
              subtitle={t(card.subtitle_key)}
            />
          );
        })}
      </CardsWrapper>
    </Container>
  );
};

export default Scene_2;
