import { createContext } from 'react'


type TLanguageShort = string

type UserContextType = {
  chatUnreadCounter: number,
  profile: {},
  referal: {},
  isAuthenticated: boolean,
  isBusinessSiteType: boolean,
  currentLanguage: TLanguageShort,
  login: (e: any) => void,
  logout: () => void,
  getTextWithSiteType: (str: string) => string
  setCurrentLanguage: (lang: TLanguageShort) => void,
}

export const UserContext = createContext<UserContextType>({})
