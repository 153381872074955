import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import EyeIcon from '../../../../public/eye.svg'
import { PreviewType } from '../../../utils/touchpoints';
import { Row } from '../../common';
import { AvatarRow } from './AvatarRow';

interface StatisticsProps {
  previews: PreviewType[],
  appliedCount: number,
  viewedCount: number
}


const StatContainer = styled.div`
  background-color: white;
  height: 34px;
  width: 100%;
  align-self: flex-end;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0px 8px;
`

const StatText = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 130%;
    color: #818FA4;
`



const Eye = styled.img`
  height: 14;
  margin-right: 1.5px;
`

export const Statistics: React.FC<StatisticsProps> = ({ previews, appliedCount, viewedCount }) => {
  const [t] = useTranslation('translations');
  return (
    <StatContainer>
      <Row style={{ alignItems: 'center' }}>
        <AvatarRow previews={previews} />
        {appliedCount ?
          <StatText>
            <span>{t('touchpoints.stats.applied_by_text','Applied by {{applied}} persons', { applied: appliedCount })}</span>
          </StatText>
          : null
        }
      </Row>
      <Row>
        <Eye src={EyeIcon} />
        <StatText>{viewedCount}</StatText>
      </Row>
    </StatContainer>
  )
}