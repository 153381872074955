import React from "react";
import styled from "styled-components";
import Next from "../../public/next.svg";

export interface IGoalCardProps {
  title: string;
  subtitle: string;
  emoji: string;
  backgroundColor: string;
  url: string;
}

const Link = styled.a`
  text-decoration: none;
  color: black;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  row-gap: 12px;
  width: 100%;
  // height: 234px;
  // width: 217px;
  border-radius: 12px;
  padding: 20px 20px 24px;
  // justify-content: space-between;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-property: box-shadow;
  :hover {
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.1);
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
`;

const EmojiText = styled.span`
  font-size: 26px;
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
`;

const Subtitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  white-space: pre-wrap;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const RightIcon = styled.img`
  width: 16px;
  height: 16px;
  align-self: flex-end;
`;

export const GoalCard: React.FC<IGoalCardProps> = ({
  title,
  subtitle,
  emoji,
  backgroundColor,
  url,
}) => {
  return (
    <Link style={{ width: '217px', flexShrink: 0, }} target={"_blank"} href={url}>
      <Container style={{ backgroundColor }}>
        <TextContent>
          <Circle>
            <EmojiText>{emoji}</EmojiText>
          </Circle>
          <TextWrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextWrapper>
        </TextContent>
        <RightIcon src={Next} />
      </Container>
    </Link>
  );
};
