import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Trans, useTranslation } from 'react-i18next';
import handsup from '../../public/handsup.png';
import altairLogo from '../../public/altair-logo.png';
import googleLogo from '../../public/google-logo.png';
import productHuntLogo from '../../public/product_hunt.png';
import productHuntMobile from '../../public/product_hunt_mobile.png';
import devices from '../devices';
import { UserContext } from '../userContext';
import { DownloadBtn } from '../components';
import { AnimatedTitle } from "../components/AnimatedTitle";
import { scrollToLinks } from '../utils/scrollToLinks';

const Title = styled.h1`
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    margin-bottom: 24px;
    white-space: pre-line;

    @media ${devices.laptop} {
        max-width: 90%;
        font-size: 24px;
        margin-bottom: 12px;
    }
`
const Description = styled.p`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #526178;
    margin-bottom: 32px;

    @media ${devices.laptop} {
        font-size: 15px;
        line-height: 150%;
        font-size: 17px;
    }
`


const RightImage = styled.img`
    width: 458px;
    height: 536px;
    margin-left: 40px;

    @media ${devices.laptop} {
        margin-top: 40px;
        margin-left: 0;
    }
`


const BackedBy = styled.div`
    background: #F5F8FF;
    border-radius: 112px;
    height: 114px;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    .icon { margin-right: 20px }
    .title {
        font-size: 24px;
        font-weight: 500;
    }
    *:not(:last-child) { margin-right: 50px }
    @media ${devices.laptop} {
        background: transparent;
        height: 115px;
        margin-bottom: -20px;
        *:not(:last-child) {
            margin-right: 25px !important;
        }
        .title {
            font-size: 14px;
            white-space: nowrap;
        }
        .icon {
            height: 23px;
            width: 20px;
            margin-right: 10px !important;
        }
    }
`

const Margin80 = styled.div`
    margin-top: 80px;

    @media ${devices.laptop} {
        margin-top: 0;
    }
`

const MarginBottom100 = styled.div`
    margin-bottom: 100px;

    @media ${devices.laptop} {
        margin-bottom: 0;
    }
`

const Margin = styled.div`
    margin-top: 40px;

    @media ${devices.laptop} {
        margin-top: 60px;
    }
`


const AppIcon = styled.img`
    display: inline-block;
    margin-right: 8px;
    position: relative;
    width: 14px;
    height: 16px;
`

const HrVertical = styled.div`
    height: 60px;
    width: 1px;
    background: #D5DCED;
`


const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 554px;
    @media ${devices.laptop} {
      max-width: auto;
    }
`

const LeftHalf = styled.div`
    width: 50%;
    @media ${devices.laptop} {
      width: auto;
    }
`

const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    @media ${devices.laptop} {
        flex-direction: column;
        width: 100%;
    }
`

const ButtonSpace = styled.div`
    width: 16px;
    height: 16px;
`

const Scene1 = () => {
    const [t] = useTranslation('translations');
    const sceneImg = require(`../../public/${t('scenes.scene-1.image')}`);
    const isMobile = useMediaQuery({ query: devices.laptop })
    const {
        getTextWithSiteType,
        isBusinessSiteType,
        referal: {
            profile,
        },
    } = useContext(UserContext)

    const title = isBusinessSiteType ? t('scenes.scene-1.title_business', 'Get new connections')
        : profile ? t('scenes.scene-1.title_referal', {
            name: profile.first_name,
        }) : ''

    return (
        <>
            <Margin />
            <div className="container container-mobile v-middle h-middle-desktop">
                <LeftHalf>
                    <TextContainer>
                        <Title>
                            {!isBusinessSiteType && !profile ? <AnimatedTitle /> : title}
                        </Title>
                        <Description>
                            <Trans
                                i18nKey={t(getTextWithSiteType('scenes.scene-1.description'))}
                                components={{
                                    bold: (<span style={{ fontWeight: '600' }} />),
                                }}
                            />
                        </Description>
                        <ButtonsRow>
                            <DownloadBtn style={{ padding: '15px 48px' }} onClick={scrollToLinks} >
                                {t('buttons.signUp', "Sign Up")}
                            </DownloadBtn>
                        </ButtonsRow>
                    </TextContainer>

                </LeftHalf>
                <div>
                    <RightImage src={sceneImg} />
                </div>
            </div>
            <Margin80 />
            <div className="container">
                <BackedBy>
                    <span className="title"><img className="icon" src={handsup} />{t('scenes.scene-1.backed')}</span>
                    <img src={altairLogo} style={{
                        width: isMobile ? 87 : 132,
                        height: isMobile ? 24 : 35
                    }} />
                    <img src={googleLogo} style={{
                        mixBlendMode: 'multiply',
                        width: isMobile ? 72 : 113,
                        height: isMobile ? 21 : 33
                    }} />
                    {!isMobile &&
                        <>
                            <HrVertical />
                            <img src={productHuntLogo} style={{
                                widtht: 152,
                                height: 43,
                                position: 'relative',
                                top: -2
                            }} />
                        </>
                    }
                </BackedBy>
            </div>
            {
                isMobile &&
                <img src={productHuntMobile} style={{
                    width: 310,
                    height: 28,
                    display: 'block',
                    margin: '0 auto',
                    marginBottom: 60
                }} />
            }
            <MarginBottom100 />
        </>
    )
}

export default Scene1
